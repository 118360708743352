import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { SimCard, UsagePackage } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import {
    CobiraTable,
    DataText,
    DateTime,
    PlaceholderText,
    SlimUuid,
    UsagePackageStatusIndicator,
    usePageNavigation,
    usePagination,
} from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { UsagePackageTypeCell } from "../../../../components/Cells/UsagePackageTypeCell/UsagePackageTypeCell";
import { createColumnHelper } from "@tanstack/react-table";

export interface SimCardUsagePackagesPanelProps {
    query: UseQueryResult<SimCard>;
}

const usagePackageColumn = createColumnHelper<UsagePackage>();
const COLUMNS = [
    usagePackageColumn.accessor("id", {
        id: "id",
        header: "ID",
        cell: row => <SlimUuid uuid={row.getValue()} />,
    }),
    usagePackageColumn.accessor("packageState", {
        id: "packageState",
        header: "State",
        cell: row => <UsagePackageStatusIndicator state={row.getValue()} withLabel />,
    }),
    usagePackageColumn.accessor("usagePackageTypeId", {
        id: "packageName",
        header: "Bundle Name",
        cell: row => (
            <UsagePackageTypeCell
                usagePackageTypeId={row.getValue()}
                property={usagePackageType => <PlaceholderText text={usagePackageType?.customName} />}
            />
        ),
    }),
    usagePackageColumn.accessor("dataRemaining.dataInBytes", {
        id: "dataRemaining",
        header: "Data Remaining",
        cell: row => <DataText bytes={row.getValue()} decimals={2} />,
    }),
    usagePackageColumn.accessor("smsRemaining", {
        id: "smsRemaining",
        header: "SMS Remaining",
        cell: row => <PlaceholderText text={`${row.getValue()}`} />,
    }),
    usagePackageColumn.accessor("activatedAt", {
        id: "activatedAt",
        header: "Activated At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    usagePackageColumn.accessor("terminatedAt", {
        id: "terminatedAt",
        header: "Terminated At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    usagePackageColumn.accessor("expiresAt", {
        id: "expiresAt",
        header: "Expires At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
];

const SimCardUsagePackagesPanel = ({ query: { data: value } }: SimCardUsagePackagesPanelProps) => {
    const { simCardApi } = useApi();

    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { data: usagePackages, isLoading: isLoadingUsagePackages } = useQuery(
        ["simcards", value?.icc, "usagepackages", pageState],
        () =>
            simCardApi.getSimCardUsagePackages({
                icc: value?.icc || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
            }),
        { enabled: value?.icc !== undefined },
    );

    const { navigate } = usePageNavigation<UsagePackage>({
        route: row => `/simcards/${value?.icc}/bundles/${row.id}`,
    });

    return (
        <TabPanel w={"100%"}>
            <CobiraTable
                columns={COLUMNS}
                data={usagePackages?.content || []}
                isLoading={isLoadingUsagePackages}
                withRowClick={{ enableHoverStyle: true, onRowClicked: navigate }}
                withPagination={{
                    pageSize: pageState.pageSize,
                    pageIndex: pageState.pageIndex,
                    onPaginationChange: setPageState,
                    totalRowCount: usagePackages?.pageProperties?.totalElements || 0,
                    totalPageCount: usagePackages?.pageProperties?.totalPages || 0,
                }}
            />
        </TabPanel>
    );
};

export default SimCardUsagePackagesPanel;
