import { SimCardCapabilityName } from "../api";

export const SimCardCapabilityNameLabels = {
    [SimCardCapabilityName.Sms]: "SMS Support",
    [SimCardCapabilityName.HardwareRegistration]: "Hardware registration",
    [SimCardCapabilityName.PrivateApn]: "Private APN",
    [SimCardCapabilityName.PublicApn]: "Public APN",
    [SimCardCapabilityName.UsagePackageControl]: "Usage packages",
};

export const SimCardCapabilityNameDescriptions = {
    [SimCardCapabilityName.Sms]: "Send an SMS to the device",
    [SimCardCapabilityName.HardwareRegistration]: "Fetches information from the device the SIM is slotted into",
    [SimCardCapabilityName.PrivateApn]: "Traffic is routed through a private network",
    [SimCardCapabilityName.PublicApn]: "Device will receive a public static IP",
    [SimCardCapabilityName.UsagePackageControl]: "Control usage through Usage Packages",
};
