import React, { useMemo } from "react";
import { BillableUsageLineClassifierEnum, CostUsageLineClassifierEnum } from "../../../api";
import { CobiraTable, DataText, formatToKSyntax, Money } from "@cobira/ui-library";
import { createColumnHelper } from "@tanstack/react-table";
import { BillingGroupCell } from "../../Cells/BillingGroupCell/BillingGroupCell";
import { Text } from "@chakra-ui/react";
import { RatePlanCell } from "../../Cells/RatePlanCell/RatePlanCell";
import { MergedUsageLine } from "../../../utils/billingReportUtils";
import UsageGroupingText from "../UsageGroupingText";
import NetworkAccessUsageGroupingText from "../NetworkAccessUsageGroupingText";

const mergedUsageColumn = createColumnHelper<MergedUsageLine>();
const COLUMNS = [
    mergedUsageColumn.accessor("billingGroupId", {
        id: "billingGroupId",
        header: "Billing Group",
        cell: row => <BillingGroupCell billingGroupId={row.getValue()} />,
    }),
    mergedUsageColumn.accessor("ratePlanId", {
        id: "ratePlanId",
        header: "Rate Plan",
        cell: row => <RatePlanCell ratePlanId={row.getValue()} />,
    }),
    mergedUsageColumn.accessor(row => row, {
        id: "grouping",
        header: "Grouping",
        cell: row => {
            if (row.getValue().classifier === "NETWORK_ACCESS") {
                return <NetworkAccessUsageGroupingText costKey={row.getValue().costKey} />;
            } else {
                return (
                    <UsageGroupingText
                        grouping={row.getValue().billableKey}
                        discriminator={row.getValue().discriminator}
                    />
                );
            }
        },
    }),
    mergedUsageColumn.accessor(row => row, {
        id: "consumption",
        header: "Consumption",
        meta: {
            isNumeric: true,
        },
        cell: row => {
            const classifier = row.getValue().classifier;
            switch (classifier) {
                case "DATA":
                    return <DataText bytes={row.getValue().amount} decimals={2} />;
                case "MO_SMS":
                    return <Text>{formatToKSyntax(row.getValue().amount)} MO SMS</Text>;
                case "MT_SMS":
                    return <Text>{formatToKSyntax(row.getValue().amount)} MT SMS</Text>;
                case "NETWORK_ACCESS":
                    return <Text>{formatToKSyntax(row.getValue().amount)} IMSIs</Text>;
                default:
                    return <Text>{formatToKSyntax(row.getValue().amount)}</Text>;
            }
        },
    }),
    mergedUsageColumn.accessor(row => row, {
        id: "cost",
        header: "Cost",
        meta: {
            isNumeric: true,
        },
        cell: row => <Money value={row.getValue().cost} currency={row.getValue().currency} decimals={2} />,
    }),
    mergedUsageColumn.accessor(row => row, {
        id: "price",
        header: "Price",
        meta: {
            isNumeric: true,
        },
        cell: row => <Money value={row.getValue().price} currency={row.getValue().currency} decimals={2} />,
    }),
    mergedUsageColumn.accessor(row => row, {
        id: "margin",
        header: "Margin",
        meta: {
            isNumeric: true,
        },
        cell: row => (
            <Money value={row.getValue().price - row.getValue().cost} currency={row.getValue().currency} decimals={2} />
        ),
    }),
];

export interface BillableUsageTableProps {
    classifierFilter?: Array<CostUsageLineClassifierEnum & BillableUsageLineClassifierEnum>;
    items: MergedUsageLine[];
    isLoading?: boolean;
}

const MergedUsageTable = ({ isLoading, items, classifierFilter }: BillableUsageTableProps) => {
    const filteredItems = useMemo(() => {
        return classifierFilter !== undefined
            ? items.filter(item => classifierFilter.find(classifier => classifier === item.classifier) !== undefined)
            : items;
    }, [classifierFilter, items]);
    return <CobiraTable columns={COLUMNS} data={filteredItems} isLoading={isLoading} />;
};

export default MergedUsageTable;
