import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { NetworkCredentialTypeCell } from "../Cells/NetworkCredentialTypeCell/NetworkCredentialTypeCell";
import NetworkInfo from "../NetworkInfo/NetworkInfo";

export interface NetworkAccessUsageGroupingTextProps {
    costKey: Set<string>;
}

/**
 * We are assuming that the cost key is a set with unknown ordering but it contains 2 values.
 * One is the PLMN of the accessed network, the other is the unique id of the network credential type it was accessed through.
 */
const NetworkAccessUsageGroupingText = ({ costKey }: NetworkAccessUsageGroupingTextProps) => {
    const PLMN_MAX_LENGTH = 6;
    const values = Array.from(costKey.values());
    if (values.length === 2) {
        const networkCredentialTypeId = values[0].length <= PLMN_MAX_LENGTH ? values[1] : values[0];
        const plmn = values[0].length <= PLMN_MAX_LENGTH ? values[0] : values[1];

        return (
            <HStack spacing={0}>
                <NetworkInfo plmn={plmn} />
                <Text>-</Text>
                <NetworkCredentialTypeCell networkCredentialTypeId={networkCredentialTypeId} showPlmn={false} />
            </HStack>
        );
    } else {
        return <Text>{values.join(", ")}</Text>;
    }
};

export default NetworkAccessUsageGroupingText;
